.bg-info {
  background-color: #68e;
  transition: background-color 0.1s ease-in-out;
}
.bg-info:not(.disabled):hover {
  background-color: #3864e9;
  transition: background-color 0.1s ease-in-out;
}
.bg-success {
  background-color: #6a6;
  transition: background-color 0.1s ease-in-out;
}
.bg-success:not(.disabled):hover {
  background-color: #4f8e4f;
  transition: background-color 0.1s ease-in-out;
}
.bg-warn {
  background-color: #ec5;
  transition: background-color 0.1s ease-in-out;
}
.bg-warn:not(.disabled):hover {
  background-color: #e9be27;
  transition: background-color 0.1s ease-in-out;
}
.bg-error {
  background-color: #e64;
  transition: background-color 0.1s ease-in-out;
}
.bg-error:not(.disabled):hover {
  background-color: #ea4015;
  transition: background-color 0.1s ease-in-out;
}
.zelda-error {
  position: absolute;
  font-family: sans-serif;
}
.invisible-background {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.0);
}
.zelda-button {
  z-index: 1002;
  position: fixed;
  width: 48px;
  height: 48px;
  border-radius: 24px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.zelda-button img {
  position: absolute;
  height: 32px;
  width: 32px;
  top: 0;
}
.zelda-button span {
  color: white;
  position: absolute;
  top: 24px;
}
.zelda-panel {
  z-index: 1001;
  position: fixed;
  display: flex;
  flex-direction: column;
  padding: 16px;
  border: 1px #aaa solid;
  border-radius: 24px;
  background-color: #fff;
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;
  visibility: hidden;
  opacity: 0;
}
.zelda-panel.open {
  transition: all 0.2s ease-in-out;
  visibility: visible;
  opacity: 1;
}
.zelda-panel .header {
  flex: 0 0 24px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.zelda-panel .header span {
  flex: 0 0 auto;
  font-size: 1.2rem;
}
.zelda-panel .header .hide-button {
  position: absolute;
  top: 4px;
  right: 32px;
  width: 6rem;
  height: 40px;
  border-radius: 20px 0 0 20px;
  cursor: pointer;
  opacity: 0.5;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: black;
}
.zelda-panel .header .hide-button img {
  height: 16px;
  width: 16px;
}
.zelda-panel .issues {
  flex: 1 1 auto;
  overflow-y: scroll;
  margin: 8px 0;
}
.zelda-panel .issues .issue {
  margin: 8px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.zelda-panel .issues .issue .button {
  height: 24px;
  width: 56px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  cursor: pointer;
}
.zelda-panel .issues .issue .button.disabled {
  opacity: 0.4;
}
.zelda-panel .issues .issue .button.vote {
  font-size: 17px;
}
.zelda-panel .issues .issue .title {
  font-size: 13px;
  padding-left: 8px;
}
.zelda-panel .issues .issue .title a {
  color: #68e;
}
.zelda-panel .footer {
  flex: 0 0 56px;
  width: 100%;
}
.zelda-panel .footer input {
  width: 100%;
  background-color: white;
  font-size: 16px;
  padding: 4px;
  border: 1px solid #707070;
  box-sizing: border-box;
}
.zelda-panel .footer > div {
  display: flex;
  flex-direction: row;
}
.zelda-panel .footer > div .request {
  margin: 0;
  padding: 4px;
  align-items: center;
  width: 50%;
  cursor: pointer;
  font-size: 14px;
}
.zelda-panel .footer > div .error {
  margin: 0;
  padding: 4px;
  align-items: center;
  width: 50%;
  cursor: pointer;
  font-size: 14px;
}
